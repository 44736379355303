<!-- 班级学生排名 -->
<template>
  <div class="rankingByClass_box_css">
    <!--  height="52.75rem" -->
    <el-carousel
      v-if="carouselNum > 0"
      height="52.75rem"
      :interval="120000"
      arrow="never"
      @change="carouselChange"
    >
      <el-carousel-item
        class="carousel-item-css"
        v-for="num in carouselNum"
        :key="num"
      >
        <div
          class="rankingByClass_item_css"
          v-for="(itb, idx) in numObj['numArr' + (num - 1)]"
          :key="idx"
        >
          <div class="className-box-css">{{ itb.className }}</div>
          <div class="studentList-box-css">
            <!-- :class="{ 'animation-css': currentNum == num - 1 }" -->
            <div>
              <div
                class="studentList-item-css"
                v-for="(item, index) in itb.studentList.slice(
                  selectNum * 5,
                  selectNum * 5 + 5
                )"
                :key="index"
              >
                <div class="ranking_index_css">
                  <img
                    v-if="item.ranking == 1"
                    src="@/assets/largeScreen/diyiming.png"
                  />
                  <img
                    v-else-if="item.ranking == 2"
                    src="@/assets/largeScreen/dierming_1.png"
                  />
                  <img
                    v-else-if="item.ranking == 3"
                    src="@/assets/largeScreen/disanming_1.png"
                  />
                  <div v-else>{{ item.ranking }}</div>
                </div>
                <img
                  class="ranking_image_css"
                  :src="item.photo"
                  @error="imgError(num, idx, selectNum * 5 + index)"
                />
                <div class="width_css">
                  <div class="ranking_userName_css">{{ item.userName }}</div>
                </div>
                <div class="ranking_zanCount_css">
                  {{ getUnit(item.Score) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import getAva from '@/utils/photoList'
// 处理无头像显示
import { fillPhoto } from '@/utils/photoList'
import { getClassStudentScoreRankingReport } from '@/api/index.js'
let current = -1
const onePage = 16 // 一页几个
let presentNum = 0
export default {
  data() {
    return {
      carouselNum: 0, // 页数
      timer: null,
      currentNum: 0, // 当前第几页，判断滚动动画
      listArr: [],
      numObj: {
        numArr0: [],
      },
      selectNum: 0,
      selectTime: null,
    }
  },
  created() {
    this.getStudentScoreRankingReport()
    this.timer = setInterval(() => {
      this.getStudentScoreRankingReport()
    }, 1000 * 60 * 30)
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer)
    }
    if (this.selectTime) {
      clearInterval(this.selectTime)
    }
  },
  methods: {
    getStudentScoreRankingReport() {
      getClassStudentScoreRankingReport({
        top: 200,
        entranceYear: localStorage.getItem('gradeNum'),
      }).then((res) => {
        console.log('res222', res)
        this.listArr = res.data
        this.getList()
      })
    },
    getList() {
      // 将班级人员加入排名，用于前三名显示不同图标
      for (let i = 0; i < this.listArr.length; i++) {
        for (let j = 0; j < this.listArr[i].studentList.length; j++) {
          this.listArr[i].studentList = fillPhoto(this.listArr[i].studentList)
          this.listArr[i].studentList[j].ranking = j + 1
        }
      }
      // 将每个班级的学生人数都递归增加到60人，保证每个都是60个就不用单独处理每个动画的速度
      function recursion(arr) {
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].studentList.length < 60) {
            if (arr[i].studentList.length == 0) {
              return
            }
            Array.prototype.push.apply(arr[i].studentList, arr[i].studentList)
            recursion(arr)
          } else if (arr[i].studentList.length > 60) {
            arr[i].studentList.splice(60, arr[i].studentList.length - 60)
          }
        }
      }
      recursion(this.listArr)

      // 只用于打印
      // for (let i = 0; i < this.listArr.length; i++) {
      //   console.log(this.listArr[i].studentList.length, "每个班级学生人数长度", this.listArr[i].className);
      // }
      this.getCarousel()
    },
    getCarousel() {
      if (this.listArr.length > onePage) {
        // 计算需要分几页
        this.carouselNum = Math.ceil(this.listArr.length / onePage)
        console.log('页', this.carouselNum)
        for (let i = 0; i < this.carouselNum; i++) {
          this.numObj['numArr' + i] = this.listArr.slice(
            i * onePage,
            (i + 1) * onePage
          )
        }
        // 如果最后一页没有被加满8个
        if (this.numObj['numArr' + (this.carouselNum - 1)].length < onePage) {
          let abn = this.listArr.length % onePage
          // 计算加满后，下次加入时应该从第几个开始加
          presentNum = onePage - abn
          this.numObj['numArr' + (this.carouselNum - 1)] = this.numObj[
            'numArr' + (this.carouselNum - 1)
          ].concat(this.listArr.slice(0, presentNum))
        }
      } else {
        this.numObj.numArr0 = this.listArr
        this.carouselNum = 1
      }
      this.selectNum = 0
      if (this.selectTime) {
        clearInterval(this.selectTime)
      }
      this.selectTime = setInterval(() => {
        this.selectNum += 1
        if (this.selectNum > 11) {
          this.selectNum = 0
        }
      }, 10500)
    },
    getPresentArr() {
      let bArr = this.listArr.slice(presentNum, presentNum + onePage)
      this.numObj['numArr' + current] = bArr
      presentNum += onePage
      // 如果当前页面没有被加满8个
      if (bArr.length < onePage) {
        presentNum = onePage - bArr.length
        this.numObj['numArr' + current] = this.numObj[
          'numArr' + current
        ].concat(this.listArr.slice(0, presentNum))
      }
      this.$forceUpdate()
    },
    // 幻灯片切换时触发
    carouselChange(e) {
      this.currentNum = e
      if (e == 0) {
        current = this.carouselNum - 1
      } else if (e < this.carouselNum) {
        current = e - 1
      } else {
        current = 0
      }
      this.selectNum = 0
      if (this.selectTime) {
        clearInterval(this.selectTime)
      }
      this.selectTime = setInterval(() => {
        this.selectNum += 1
      }, 10500)
      setTimeout(() => {
        this.getPresentArr()
      }, 500)
    },
    getUnit(e) {
      let size = Number(e)
      if (size < 10000) {
        return size
      } else if (size < 10000 * 1000) {
        return (size / 10000).toFixed(2) + '万'
      } else {
        return (size / (10000 * 1000)).toFixed(2) + '千万'
      }
    },
    imgError(num, idx, index) {
      this.numObj['numArr' + (num - 1)][idx].studentList[index].photo = getAva()
    },
  },
}
</script>
<style scoped>
.rankingByClass_box_css {
  width: 995px;
  /* height: 490px; */
  /* height: 854px; */
}
.rankingByClass_item_css {
  width: 241px;
  /* height: 240px; */
  height: 254.75px;
  background-color: #ffffff52;
  border-radius: 10px;
  overflow: hidden;
  border: #fff 1px solid;
  box-sizing: border-box;
  flex-shrink: 0;
  padding: 8px 8px 0;
}
/deep/ .el-carousel__button {
  width: 10px;
}
/deep/ .el-carousel__indicators--horizontal {
  bottom: 0;
  left: 0 !important;
  right: 0 !important;
  transform: none;
  text-align: center;
}
.carousel-item-css {
  box-sizing: border-box;
  display: flex;
  /* align-items: center; */
  flex-wrap: wrap;
}
.carousel-item-css > div {
  /* margin-bottom: 10px; */
  margin-right: 10px;
}
.carousel-item-css > div:nth-child(4n) {
  margin-right: 0px !important;
}
.className-box-css {
  text-align: center;
  margin-bottom: 3px;
  font-family: 'crjk';
  color: #8a70ff;
  font-size: 25px;
  font-weight: 600;
}
.studentList-box-css {
  height: 200px;
  overflow: hidden;
}
/* .animation-css {
  animation: 45s rankingByClassRowup linear;
} */
.studentList-item-css {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  flex-shrink: 0;
}
.ranking_image_css {
  width: 34px;
  height: 34px;
  border-radius: 1000px;
  flex-shrink: 0;
  margin-right: 6px;
  object-fit: cover;
}
.ranking_index_css {
  flex-shrink: 0;
  width: 30px;
  height: 30px;
  margin-right: 6px;
  text-align: center;
  line-height: 30px;
  font-size: 18px;
  color: #a0a0a098;
  font-weight: bold;
}
.ranking_index_css > img {
  width: 30px;
  height: 30px;
}
.ranking_userName_css {
  font-size: 16px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: break-all;
  word-wrap: break-word;
}
.ranking_zanCount_css {
  font-size: 16px;
  flex-shrink: 0;
  margin-left: 10px;
}
.width_css {
  flex-grow: 1;
}

@keyframes rankingByClassRowup {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(0, -2150px, 0);
    transform: translate3d(0, -2150px, 0);
  }
}
</style>
