<template>
  <div class="ps_pages_Css">
    <div v-if="showView">
      <!-- 班级请假人数 -->
      <!-- <classRanking class="classRanking-box-css" /> -->
      <!-- 班级男女人数 -->
      <!-- <classNumber class="classNumber-box-css" /> -->
      <!-- 年级学生排名 -->
      <grade50 class="grade50-box-css" />
      <!-- 教师排名 -->
      <teacherRanking class="teacherRanking-box-css" />
      <!-- 通知公告 -->
      <notification class="notification-box-css" />
      <!-- 各班级人员排名滚动显示 -->
      <rankingByClass class="rankingByClass-box-css" />
      <!-- 德智体美劳 -->
      <!-- <intellectual class="intellectual-box-css" /> -->
      <!-- 出勤情况 -->
      <tableA class="tableA-box-css" />
    </div>

    <el-dialog
      title="请选择年级"
      append-to-body
      :show-close="showView"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="30%"
    >
      <el-select
        style="width:100%"
        v-model="gradeValue"
        placeholder="请选择年级"
      >
        <el-option
          v-for="item in gradeList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="gradeClick()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment'
// import classRanking from "./components/classRanking.vue";
import grade50 from './components/grade50.vue'
import teacherRanking from './components/teacherRanking.vue'
import notification from './components/notification.vue'
import rankingByClass from './components/rankingByClass.vue'
import intellectual from './components/intellectual.vue'
// import classNumber from "./components/classNumber.vue";
import tableA from './components/tableA.vue'
export default {
  components: {
    // classRanking,
    grade50,
    teacherRanking,
    notification,
    rankingByClass,
    intellectual,
    // classNumber,
    tableA,
  },
  data() {
    return {
      showView: false,
      dialogVisible: false,
      gradeList: [],
      gradeValue: '',
    }
  },
  mounted() {
    this.$bus.$off('gradeCut')
    this.$bus.$on('gradeCut', () => {
      this.gradeValue = localStorage.getItem('gradeNum')
      this.getYear()
    })
  },
  created() {
    if (this.$route.query.grade) {
      localStorage.setItem('gradeNum', this.$route.query.grade)
    }
    let gradeNum = localStorage.getItem('gradeNum')
    if (!gradeNum) {
      this.showView = false
      this.getYear()
    } else {
      this.showView = true
    }
  },
  methods: {
    getYear() {
      let ya = moment().format('YYYY')
      let mm = Number(moment().format('MM'))
      let num = 6
      this.gradeList = []
      if (mm < 9) {
        for (let i = 0; i < num; i++) {
          this.gradeList.push({
            value: Number(ya) - i - 1,
            label: Number(ya) - i - 1,
          })
        }
      } else {
        for (let i = 0; i < num; i++) {
          this.gradeList.push({
            value: Number(ya) - i,
            label: Number(ya) - i,
          })
        }
      }
      this.dialogVisible = true
    },
    gradeClick() {
      localStorage.setItem('gradeNum', this.gradeValue)
      this.dialogVisible = false
      this.showView = false
      setTimeout(() => {
        this.showView = true
      }, 50)
    },
  },
}
</script>
<style>
@font-face {
  font-family: 'crjk';
  src: url(../../assets/largeScreen/54115de07a5907a9b1574992473.ttf);
}
@font-face {
  font-family: 'pmzdb';
  src: url(../../assets/largeScreen/5c8a071de32c71552549661.ttf);
}
@font-face {
  font-family: 'wdzjyhx';
  src: url(../../assets/largeScreen/81425e6332500e3301583559248.ttf);
}
</style>
<style scoped>
.ps_pages_Css {
  /* background-image: url("../../assets/largeScreen/5d203aeceeb331562393324642.jpg");
  background-size: cover; */
  background-color: #ebeefd;
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}
.classRanking-box-css {
  position: absolute;
  top: 520px;
  right: 20px;
}
.tableA-box-css {
  position: absolute;
  /* top: 520px; */
  top: 548.5px;
  right: 20px;
}
.grade50-box-css {
  position: absolute;
  top: 20px;
  left: 20px;
}
.teacherRanking-box-css {
  position: absolute;
  /* top: 520px; */
  top: 548.5px;

  left: 20px;
}
.notification-box-css {
  position: absolute;
  top: 20px;
  right: 20px;
}
.rankingByClass-box-css {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
}
.intellectual-box-css {
  position: absolute;
  top: 520px;
  left: 415px;
}
.classNumber-box-css {
  position: absolute;
  top: 735px;
  right: 20px;
}

.dialog-bg-color {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 999;
  background-color: #ebeefd;
}
</style>
